<template>
  <div class="n-footer">


    <div class="container">

      <div class="f1">
        <a href="/">网站首页</a>
        <a href="/#/hy">尊贵会员</a>
        <a href="/#/zw">会员管理制度</a>
        <a href="/#/zw2">纠纷处理</a>
        <a href="/#/jf">用户协议</a>
        <a href="/#/zw3">隐私政策</a>
        <a href="/#/info">关于我们</a>
      </div>


      <div class="f1">
        <a href="javascript:;">北京极致唱响科技有限公司</a>
        <a href="javascript:;">网站名称：极致唱响</a>
        <a href="javascript:;">电话： 18518038370</a>
        <a href="javascript:;">地址：北京市海淀区海淀大街38号楼8层10-11</a>
      </div>


      <div class="f1">
        <a href="https://www.shdf.gov.cn/shdf/channels/740.html">扫黄打非入口</a>
        <a href="https://www.12377.cn/">举报专区</a>
        <a href="http://cyberpolice.mps.gov.cn/wfjb/">网络110报警服务</a>
        <a href="https://www.12377.cn/">中国互联网举报中心</a>
        <a href="https://www.12377.cn/">网络有害信息举报</a>
        <a href="https://www.12377.cn/node_548446.htm">网络举报APP下载</a>
        <a href="https://jbts.mct.gov.cn/">全国文化和旅游市场网上举报投诉</a>
      </div>

      <div class="f1">
        © 2024 jizhiktv.com 版权保护声明 --
        <a href="https://beian.miit.gov.cn/#/Integrated/index">网站ICP备案：京ICP备19033145号-1</a>
      </div>
    </div>

    <img class="foot-img" src="/imgs/f-logo.png">
  </div>
</template>

<script>
export default {
  name: "nav-footer",
  props: {
    email: String,
    phone: String,
    icp: String,
    name: String,
    addr: String,
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.n-footer {
  width: 100%;
  background-color: #000;
  padding: 15px 0;
  box-sizing: border-box;
  clear: both;

  position: relative;

  .foot-img {
    width: 150px;
    height: 150px;
    background-color: #fff;
    display: block;
    position: absolute;
    right: 5%;
    top: 10%;
    border-radius: 50%;
  }

  .container {
    .f1 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;
      color: #cecbcb;

      a {
        font-size: 12px;
        color: #cecbcb;
        margin: 0px 10px;
      }
    }
  }
}
</style>
