<template>
  <div class="header">
    <!-- 上层 -->
    <div class="n-s">
      <!-- logo -->
      <div class="n-logo">
        <!-- <img src="/imgs/logo.png" alt="" /> -->
        <!-- <h2> <i class="iconfont icon-yinle3"></i>极致唱响</h2> -->
        <h2> <img src="/imgs/图片1.png">极致唱响</h2>
      </div>

      <!-- jj -->
      <p>
        最好的音乐是这种音乐，它能够使最优秀.最有教养的人快乐，特别是使那个在品德和修养上最为卓越的一个人快乐。——柏拉图
      </p>

      <!-- 登录注册 -->
      <div class="n-login">
        <!-- 登录 / 注册按钮，未登录时显示 -->
        <span @click="goLogin()" v-show="LoggedShow" class="n-login"> 登录 / 注册</span>

        <!-- 显示当前登录的用户名 -->
        <span class="n-info" v-show="!LoggedShow" v-text="nv_user.Username"></span>

        <!-- 退出登录按钮，已登录时显示 -->
        <span class="n-info" @click="logout()" v-show="!LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i> 退出登录
        </span>
      </div>
    </div>

    <!-- 导航菜单 -->
    <div class="n-x">
      <ul>
        <li @click="go1()"><i class="iconfont icon-shouye"></i><a href="javascript:;"> 网站首页</a></li>
        <li @click="go5()"><i class="iconfont icon-erji1"></i><a href="javascript:;"> 音乐</a></li>
        <!-- <li @click="go2()"><a href="javascript:;">精彩直播</a></li> -->

        <li @click="go4()"><i class="iconfont icon-31huiyuan"></i><a href="javascript:;"> 尊贵会员</a></li>
        <li @click="go8()"><i class="iconfont icon-qianshuxieyi"></i><a href="javascript:;"> 会员管理制度 </a></li>
        <li @click="go6()"><i class="iconfont icon-jiufenchuli"></i><a href="javascript:;"> 纠纷处理</a></li>
        <li @click="go7()"><i class="iconfont icon-shouhouwuyou"></i><a href="javascript:;"> 售后服务</a></li>
        <li @click="go3()"><i class="iconfont icon-guanyuwomen"></i><a href="javascript:;"> 关于我们</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
      // 是否吸顶
      isFixed: false,
      nv_user: {}, // 当前登录用户
      LoggedShow: true, // 用于控制显示"登录/注册"还是用户名
    };
  },
  mounted() {
    // 页面加载时，检查 localStorage 中是否有当前登录的用户
    const currentUser = JSON.parse(window.localStorage.getItem("user"));
    if (currentUser) {
      this.nv_user = currentUser; // 设置当前用户
      this.LoggedShow = false; // 用户已登录，隐藏"登录/注册"按钮
    }
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }

    },
    // 退出登录逻辑
    logout() {
      // 清除 localStorage 中的当前用户信息
      window.localStorage.removeItem("user");
      window.localStorage.setItem("state", "1"); // 1 表示未登录状态
      this.LoggedShow = true; // 恢复为未登录状态
      this.nv_user = {}; // 清空当前用户
      this.$router.push("/"); // 跳转到首页
      window.location.reload(); // 刷新页面
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },

    // 网站首页
    go1() {
      this.$router.push("/index");
    },
    // 精彩动漫
    go2() {
      this.$router.push("/ac");
      // this.$router.push("/");

    },
    // 关于我们
    go3() {
      this.$router.push("/info");
    },
    // 尊贵会员
    go4() {
      this.$router.push("/hy");
    },
    // 音乐列表
    go5() {
      this.$router.push("/mlist");
    },
    // 纠纷处理
    go6() {
      this.$router.push("/zw2");
    },
    go7() {
      this.$router.push("/ac2");
    },
    // 会员管理制度
    go8() {
      this.$router.push("/zw");
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.initHeight, false);
  },
};
</script>
<style lang="scss" scope>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.header {
  position: sticky;
  top: 0;
  z-index: 999;

  // 上层
  .n-s {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;

    // logo
    .n-logo {
      width: 19%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: $colorB;
      background-color: #ff4f48;

      h2 {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        color: #fff;

        i {
          font-size: 33px;
          margin-right: 10px;
        }
      }

      img {
        
        height: 40px;
        margin-right: 5px;
      }
    }

    p {
      display: flex;
      height: 100%;
      flex: 1;
      font-size: 12px;
      // color: #999;
      color: #333;
      font-weight: bold;
      align-items: center;
      padding-left: 30px;
      box-sizing: border-box;
    }

    // 登录注册
    .n-login {
      width: 25%;
      height: 100%;
      // background-color: #ff6600;
      display: flex;
      justify-content: space-around;
      align-items: center;

      span {
        cursor: pointer;
      }

      .n-login {
        width: 130px;
        height: 35px;
        line-height: 35px;
        border: 1px solid #ccc;
        color: #4b5cc4;
        text-align: center;
        display: inline-block;
        border-radius: 30px;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background-color: #4b5cc4;
          color: #fff;
          font-weight: normal;
        }
      }

      .n-zc {
        width: 130px;
        height: 35px;
        line-height: 35px;
        background: #4b5cc4;
        color: #fff;
        text-align: center;
        display: inline-block;
        border-radius: 30px;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.2s;
      }
    }
  }

  // 下层
  .n-x {
    width: 19%;
    // height: 500px;
    position: fixed;
    background: #fff;
    top: 12.5%;
    left: 0;
    z-index: 999;
    padding: 0;
    border: 1px solid #ccc;

    ul {
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;

      li {
        width: 100%;
        text-align: center;
        height: 90px;
        line-height: 100px;
        cursor: pointer;
        transition: all 0.2s;
        display: flex;
        align-items: center;


        &:hover {
          background-color: #dbe7ed;

          a {
            color: #4b5cc4;
          }
        }

        a {
          font-size: 16px;
          text-decoration: none;
          color: #0059a5;
          font-weight: bold;
          margin-left: 5%;


        }

        i {
          width: 30%;
          display: block;
          display: flex;
          justify-content: end;
          font-size: 18px;
          // font-weight: bold;
          color: $colorB;
        }
      }
    }
  }
}
</style>
