<template>
  <div>
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container" style="padding-left: 10%">
      <h2>北京极致唱响科技有限公司 </h2>
      <h3>会员管理制度</h3>

      <div class="box-con">
        <h3>1：会员下载付费歌曲的策略调整</h3>
        <p>
          为支持正版音乐,会员下载权益调整为在会员有效期内,仅能在网站内下载歌曲放,不支持歌曲导出到第三方设备播放。
        </p>
        <p>
       
          会员过期后,已下载的付费歌曲将不再支持离线播放,但歌曲文件会继续保留,重新开通会员后可继续离线播放。
        </p>

        <h3>2：为什么会员下载的付费歌曲在第三方设备无法播放？</h3>

        <p>
          支持正版音乐,支持版权方,会员下载的歌曲为加密格式,不支持付费歌曲导出到第三方设备播放。
        </p>

        <h3>3：充值成功，但还不是VIP,该怎么办？</h3>
        <p>请退出账号重新登录，试一下</p>

        <h3>4：开通豪华VIP有哪几种方式？</h3>
        <p>目前网站VIP支持支付宝开通方式。</p>

        <h3>5：如何计算一个月的周期？</h3>

        <p>服务按31天/月计算。</p>

        <h3>6；音乐包的有效下载量是多少？</h3>
        <p>
          <!-- 音乐包在有效期内，每个月可下载300首付费音乐，豪华音乐包可下载500首，普通音乐包下载到达300首后，可购买扩容包，升级到豪华音乐包，继续享用音乐包特权。 -->
          音乐包在有效期内，每个月可下载300首付费音乐，享用音乐包特权。
        </p>

        <h3>7：下载已购买的歌曲还扣除额度吗？</h3>
        <p>只要在音乐包有效期间内，已购买的付费歌曲再次下载时，不会扣除下载额度。</p>

        <h3>8：音乐包过期了还可以下载之前下载过的歌曲吗？</h3>
        <p>不可以，只有在音乐包有效期内，可以享受无限下载特权，音乐包过期后，不能享受此特权</p>

        <h3>9：单曲购买的歌曲，可以一直反复下载吗？</h3>
        <p>可以，通过单曲购买或购买的专辑内的歌曲，可以永久享受下载特权</p>



        <h3>10：海外用户可以使用音乐包下载付费歌曲吗？</h3>
        <p>非常抱歉，音乐包仅对大陆地区用户提供服务，海外用户是无法使用的</p>

        <p><br /></p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "极致唱响";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
  padding-top: 60px;
  box-sizing: border-box;
  h2 {
    font-size: 21px;
    color: #333;
    text-align: center;
  }
  h3 {
    font-size: 18px;
    color: #333;
    text-align: center;
    margin: 10px 30px;
  }

  .box-con {
    font-size: 14px;
    color: #888;
    line-height: 40px;
    h3 {
      text-align: left;
      margin: 10px 0;
      margin-top: 25px;
    }
    p {
      text-align: left;

    }
  }
}
</style>
