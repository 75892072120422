<template>
  <div class="sound">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>
    <div class="box">

      <!-- 上层 -->
      <div class="s-t">
        <!-- 图片 -->
        <div class="s-img">
          <img src="/imgs/sound.png" :style="{ transform: 'rotate(' + angle + 'deg)' }" />
        </div>
        <!-- 信息 -->
        <div class="s-info">
          <!-- 歌曲名称 -->
          <div class="s-title">{{ this.title }}</div>
          <!-- 歌手 -->
          <div class="s-name">作曲:{{ this.name }}</div>
          <!-- 发行时间 -->
          <div class="s-time">发行时间:{{ this.time }}</div>
          <!-- 播放器 -->
          <div class="s-au">
            <vz>
              <a1 :msrc="this.src"></a1>
            </vz>
          </div>
        </div>
      </div>

      <!-- 下层 -->
      <div class="s-con">
        <p>{{ this.lrc }}</p>
        <p>{{ this.lrc2 }}</p>
        <p>{{ this.lrc3 }}</p>
        <p>{{ this.lrc4 }}</p>
        <p>{{ this.lrc5 }}</p>
        <p>{{ this.lrc6 }}</p>
        <p>{{ this.lrc7 }}</p>
        <p>{{ this.lrc8 }}</p>
        <p>{{ this.lrc9 }}</p>
        <p>{{ this.lrc10 }}</p>
        <p>{{ this.lrc11 }}</p>
      </div>

      <!-- 定宽布局 -->
      <div class="container">


      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
import a1 from "./../components/Ma";
import vz from "./../components/vz";
export default {
  components: {
    a1,
    vz,
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
      // 获取 id
      myId: this.$route.query.id,
      // 歌名
      title: "",
      // 作者
      name: "",
      // 时间
      time: "",
      // audio
      src: "",
      // 歌曲 数据
      msc1: [],
      angle: 0, // 当前旋转角度

      // 歌词
      lrc: "",
      lrc2: "",
      lrc3: "",
      lrc4: "",
      lrc5: "",
      lrc6: "",
      lrc7: "",
      lrc8: "",
      lrc9: "",
      lrc10: "",
      lrc11: "",
      lrc12: "",
      lrc13: "",
      lrc14: "",
      lrc15: "",
      lrc16: "",
      lrc17: "",
      lrc18: "",
      lrc19: "",
      lrc20: "",
    };
  },
  // beforeMount() {
  //   this.init();
  // },
  mounted() {
    this.init();
    document.title = "极致唱响";
    this.startRotation();
    // setTimeout(() => {
    //   this.$message({
    //     message: '试听结束，请付款听完整版',
    //     type: 'warning'
    //   });
    //   this.$router.push("/pay");
    // }, 15000);


  },
  methods: {
    init() {
      // console.log(data);
      this.axios.get("/mock/index.json").then((res) => {
        var vl = res.data;
        var data = vl.find((vl) => vl.id == this.myId);
        this.title = data.title;
        this.name = data.name;
        this.time = data.time;
        this.src = data.src;
        this.lrc = data.lrc;
        this.lrc2 = data.lrc2;
        this.lrc3 = data.lrc3;
        this.lrc4 = data.lrc4;
        this.lrc5 = data.lrc5;
        this.lrc6 = data.lrc6;
        this.lrc7 = data.lrc7;
        this.lrc8 = data.lrc8;
        this.lrc9 = data.lrc9;
        this.lrc10 = data.lrc10;
        this.lrc11 = data.lrc11;
        this.lrc12 = data.lrc12;
        this.lrc13 = data.lrc13;
        this.lrc14 = data.lrc14;
        this.lrc15 = data.lrc15;
        this.lrc16 = data.lrc16;
        this.lrc17 = data.lrc17;
        this.lrc18 = data.lrc18;
        this.lrc19 = data.lrc19;
        this.lrc20 = data.lrc20;

        // this.src = data.src;
        // console.log(this.src);
      });

    },
    open3() {

    },
    startRotation() {
      // 每100毫秒更新一次角度，实现旋转效果
      setInterval(() => {
        this.angle += 2; // 每次增加2度
        if (this.angle >= 360) {
          this.angle = 0; // 角度大于360时重置为0
        }
      }, 100); // 100毫秒的间隔时间
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.sound {
  background: url('/imgs/bj2.jpg') no-repeat center;

  .s-t {
    width: 100%;
    // height: 300px;
    @include flex(center);
    padding-left: 20%;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.5);

    // 左侧 信息
    .s-info {
      width: 30%;
      height: 100%;
      padding: 30px 0 0 30px;
      box-sizing: border-box;
      text-align: center;

      // 歌曲名称
      .s-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      // 歌手
      .s-name {
        font-size: 18px;
        margin-bottom: 20px;
      }

      // 发行时间
      .s-time {
        font-size: 18px;
        margin-bottom: 20px;
      }

      .s-au {
        width: 300px;
        // height: 60px;
        // background-color: #fff;
        margin: 0 auto;
      }
    }

    // 右侧图片
    .s-img {
      // margin-left: 30px;
      width: 310px;
      height: 310px;

      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        transition: transform 0.1s linear;
      }
    }
  }

  .s-con {
    // background: url("/imgs/bj1.jpg") no-repeat center;
    background: rgba(255, 255, 255, 0.5);
    margin: 0 auto;
    padding-left: 10%;
    padding-top: 3%;

    p {
      width: 300px;
      line-height: 50px;
      font-size: 16px;
      margin:0 auto;
      // margin-bottom: 10px;
      color: #888;
    }
  }

  .container {}
}
</style>
