<template>
  <div>
    <!-- <nav-header></nav-header> -->
    <router-view></router-view>
    <nav-footer
      :email="this.email"
      :phone="this.phone"
      :name="this.name"
      :addr="this.addr"
      :icp="this.icp"
    ></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
export default {
  name: "home",
  components: {
    NavFooter,
  },
  data() {
    return {
      email: "邮箱：admin@cwlo.com.cn",
      phone: "座机：010-58125117",
      icp: "备案号：京ICP备15044664号-2",
      name: "中福彩(北京)运营管理有限公司",
      addr: "地址：北京市朝阳区北四环东路73号院1号楼7层722室",
    };
  },
};
</script>
<style lang="" scoped></style>
