<template>
    <div class="order-pay">
        <!-- 导航栏 -->
        <nav-header :href="this.href"></nav-header>
        <!-- 主体 -->
        <div class="wrapper">
            <div class="container">
                <!-- 提交信息容器 -->
                <div class="order-wrap">
                    <!-- 提交信息 -->
                    <div class="item-order">
                        <!-- 图标 -->
                        <div class="icon-succ"></div>
                        <!-- 信息 -->
                        <div class="order-info">
                            <h2>订单提交成功！去付款咯~</h2>
                            <!-- <p>请在<span>30分</span>内完成支付, 超时后将取消订单</p> -->
                        </div>
                        <!-- 价格 -->
                        <div class="order-total">
                            <p>应付总额：<span>3</span>元</p>
                        </div>
                    </div>
                </div>

                <!-- 支付方式 -->
                <div class="item-pay">
                    <!-- <h3>请选择以下支付方式付款</h3> -->
                    <h3>请扫码付款</h3>
                    <div class="pay-way">
                        <!-- <p>支付平台</p> -->
                        <!-- <p>支付二维码</p> -->
                        <!-- <div
                class="pay pay-ali"
                :class="{ checked: payType == 1 }"
                @click="paySubmit(1)"
              ></div>
              <div
                class="pay pay-wechat"
                :class="{ checked: payType == 2 }"
                @click="paySubmit(2)"
              ></div> -->


                        <img src="/imgs/pay.png" alt="" style="width: 39%;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import NavHeader from "./../components/NavHeader";
export default {
    name: "pay",
    components: {
        NavHeader,
    },
    data() {
        return {
            href: "/#/index",
            payType: 1,
        };
    },
    methods: {

        paySubmit(payType) {
            this.payType = payType;
        },
    },
    mounted() {
        document.title = "好美信息技术（北京）有限公司";
    },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.order-pay {

    // margin-top: 80px;
    .wrapper {
        background-color: #f5f5f5;
        padding-top: 30px;
        padding-bottom: 61px;
        padding-left: 19%;
        box-sizing: border-box;

        // 提交信息容器
        .order-wrap {
            padding: 62px 50px;
            background-color: #fff;
            font-size: 14px;
            margin-bottom: 30px;

            // 提交细节
            .item-order {
                display: flex;
                align-items: center;

                // 图标
                .icon-succ {
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    background: url("/imgs/icon-gou.png") #80c58a no-repeat center;
                    background-size: 60px;
                    margin-right: 40px;
                }

                // 信息
                .order-info {
                    margin-right: 248px;

                    h2 {
                        font-size: 24px;
                        color: #333333;
                        margin-bottom: 20px;
                    }

                    p {
                        color: #666666;

                        span {
                            color: #ff6700;
                        }
                    }
                }

                // 价格
                .order-total {
                    &>p:first-child {
                        margin-bottom: 30px;
                    }

                    span {
                        font-size: 28px;
                        color: #ff6700;
                    }

                    .icon-down {
                        display: inline-block;
                        width: 14px;
                        height: 10px;
                        background: url("/imgs/icon-down.png") no-repeat center;
                        background-size: contain;
                        margin-left: 9px;
                        transition: all 0.5s;
                        cursor: pointer;

                        &.up {
                            transform: rotate(180deg);
                        }
                    }

                    .icon-up {
                        transform: rotate(180deg);
                    }
                }
            }

            // 订单细节
            .item-detail {
                border-top: 1px solid #d7d7d7;
                padding-top: 47px;
                padding-left: 130px;
                font-size: 14px;
                margin-top: 45px;

                .item {
                    margin-bottom: 19px;

                    .detail-title {
                        float: left;
                        width: 100px;
                    }

                    .detail-info {
                        display: inline-block;

                        img {
                            width: 30px;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }

        // 支付方式
        .item-pay {
            padding: 26px 50px 72px;
            background-color: #ffffff;
            color: #333333;
            text-align: center;

            h3 {
                font-size: 20px;
                font-weight: 200;
                color: #333333;
                padding-bottom: 24px;
                border-bottom: 1px solid #d7d7d7;
                margin-bottom: 26px;
            }

            .pay-way {
                font-size: 18px;

                .pay {
                    display: inline-block;
                    width: 188px;
                    height: 64px;
                    border: 1px solid #d7d7d7;
                    cursor: pointer;

                    &:last-child {
                        margin-left: 20px;
                    }

                    &.checked {
                        border: 1px solid #ff6700;
                    }
                }

                .pay-ali {
                    background: url("/imgs/icon-ali.png") no-repeat center;
                    background-size: 103px 38px;
                    margin-top: 19px;
                }

                .pay-wechat {
                    background: url("/imgs/icon-wechat.png") no-repeat center;
                    background-size: 103px 38px;
                }
            }
        }
    }
}</style>
  