<template>
  <div>
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container" style="padding-left: 10%">
      <h2>北京极致唱响科技有限公司 </h2>
      <h3>售后服务</h3>

      <div class="box-con">
        
        <h3>一、售后政策</h3>
        <p>&nbsp;</p>
        <p>
          1、售后服务的宗旨
          宗旨是“专业、规范、团队、高效”。在最短的时间内，以最高的效率提供承诺的各项规范、专业的服务，让用户满意。
        </p>
        <p>&nbsp;</p>
        <p>
          2、售后服务承诺
          我公司设立了售后服务中心小组，在接到用户的售后需求后，我中心在10分钟内给予回复，并及时高效的妥善处理用户的问题。
        </p>
        <p>&nbsp;</p>
        <p>
          3、售后人员配置
          售后服务中心小组目前是刚设立阶段，共计5人，已经通过了专业的培训达到上岗要求，依然保持学习的态度努力提高服务水平。
        </p>

        <h3>二、发票问题</h3>
        <p>&nbsp;</p>
        <p>
          在本平台下单且确认订单后的用户，可联系客服中心小组开具增值税发票，邮箱：1772707553@qq.com。
        </p>
        <p>&nbsp;</p>

        <h3>三、退款说明</h3>
        <p>
           用户下单后，未确认订单之前可以取消订单，费用会原路退还。
          用户下单后，且已确认订单的话，需发起退单审批，审批五个工作日内会给予回复，经审核无误后，费用会原路退还
        </p>

        <!-- <img src="/imgs/sh1.png"  style="display: block;margin: 15px auto;"> -->
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "极致唱响";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
  padding-top: 60px;
  box-sizing: border-box;
  h2 {
    font-size: 21px;
    color: #333;
    text-align: center;
  }
  h3 {
    font-size: 18px;
    color: #333;
    text-align: center;
    margin: 10px 30px;
  }

  .box-con {
    font-size: 14px;
    color: #888;
    line-height: 40px;
    h3 {
      text-align: left;
      margin: 10px 0;
    }
    p {
      text-align: left;
      margin: 15px 0;
      text-indent: 15px;
    }
  }
}
</style>
