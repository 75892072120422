<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽布局 -->
    <div class="my-container">
      <!-- 活动推荐  st -->
      <div class="banenr" style="margin-bottom: 35px;">
        <img src="/imgs/info.jpg" alt="" />
      </div>
      <!-- 活动推荐  end -->



      <!-- 地图 -->
      <!-- <div class="dt">
        <img src="/imgs/dt.png" alt="" />
      </div> -->

      <!-- <h3 style="margin-bottom: 5%;">经营地址：北京市海淀区海淀大街38号楼8层10-11</h3> -->
      <div class="con">
        <!-- 公司介绍 st -->
        <div class="js">
          <p>
            北京极致唱响科技有限公司成立于2019年06月11日，注册地位于北京市海淀区海淀大街38号楼8层10-11
          </p>
          <!-- <p>
          经营范围包括技术开发、技术推广、技术转让、技术咨询、技术服务；软件开发；基础软件服务；设计、制作、代理、发布广告；版权贸易；销售自行开发后的产品（除电子产品、服装等实体店）；计算机系统服务；应用软件服务；从事互联网文化活动；互联网信息服务。（市场主体依法自主选择经营项目，开展经营活动；从事互联网文化活动、互联网信息服务以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。） 
        </p> -->
        </div>
        <!-- 公司介绍 end -->

        <h3>历史介绍：</h3>

        <div class="about2">
          <div class="wrapper clearfix-time">

            <ul class="clearfix-time">
              <li>
                <h4 class="f30">2019年6月</h4>
                <p class="f14">公司成立</p>
              </li>
              <li>
                <h4 class="f30">2019年9月</h4>
                <p class="f14">获得赛富基金天使轮投资</p>
              </li>
             
              <li>
                <h4 class="f30">2021年11月</h4>
                <p class="f14">荣获专精特新中小企业</p>
              </li>
              <li>
                <h4 class="f30">2022年2月</h4>
                <p class="f14">荣获高新技术企业</p>
              </li>
             
              <li>
                <h4 class="f30">2024年8月</h4>
                <p class="f14">荣获科技型中小企业 </p>
              </li>
            </ul>
          </div>
        </div>
        <h3>加入我们：邀请志同道合的音乐达人加入我们，联系邮箱：1772707553@qq.com</h3>
      </div>
    </div>

    <!-- 弹框 -->
    <modal title="请选择支付方式" btnType="1" modalType="middle" :showModal="showModal" @submit="showModal = false"
      @cancel="showModal = false">
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <p>扫码收费：2元</p>
      </template>
    </modal>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
// import navigation from "./../components/navigation";
// import as from "./../components/a";
import Modal from "./../components/Modal.vue";
// import Ma from "./../components/Ma.vue";

export default {
  name: "index",
  data() {
    return {
      tabShow: true,
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "/",
      // 音乐列表
      songList: [],
      // 总页数
      pages: 6,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      drawer: false,
      direction: "ltr",
      my_lyc: [],
      my_title: "",
      imgUrl: "",
      showModal: false,
      m1: [],
      m2: [],
      m3: [],
      m4: [],
      m5: [],
      m6: [],
      m7: [],
      m8: [],
    };
  },
  components: {
    NavHeader,
    Modal,
  },
  mounted() {
    document.title = "极致唱响";
    this.init();

  },
  methods: {
    // 真实支付逻辑
    alipay(data, index) {
      console.log(data);
      var payAmount = 1.0; //支付价格
      var orderName = "音乐下载支付"; //订单名称
      var id = index; //订单名称
      window.open(
        "http://www.cwlo.com.cn/alipay/index.php?payAmount=" +
        payAmount +
        "&orderName=" +
        orderName +
        "&id=" +
        id
      );
    },
    init() {
      // 渲染数据
      this.axios.get("/mock/a.json").then((res) => {
        var data = res.data;
        this.m1 = data.slice(0, 9);
        this.m2 = data.slice(9, 39);
        this.m3 = data.slice(39, 49);
        this.m4 = data.slice(49, 58);
        this.m5 = data.slice(58, 67);
        this.m6 = data.slice(67, 76);
        this.m7 = data.slice(76, 106);
        this.m8 = data.slice(106, 136);
      });
    },
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },
    // 收费逻辑
    gopay() {
      console.log(this.state);

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("点击登录去购买~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },

  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("https://www.jq22.com/jquery/bootstrap-3.3.4.css");

.index {

  .con {
    background: url('/imgs/info-banner.jpg') no-repeat;
    background-size: cover;


  }

  .about2 .tit {
    text-align: center;
  }

  .about2 ul {
    background: url('/imgs/sy51.png') repeat-y center;
    width: 35%;
  }

  .clearfix-time {
    display: block;
    zoom: 1;
  }

  ol,
  ul {
    padding-left: 0;
    list-style: none;
  }

  .about2 .tit h6 {
    color: #666;
  }

  .f12 {
    font-size: 12px;
  }

  th {
    font-style: normal;
    font-weight: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }

  .about2 .tit h5 {
    color: #383838;
    margin: 15px 0 45px;
  }

  .f24 {
    font-size: 24px;
  }

  .about2 li:before {
    background: url('/imgs/sy50.png') no-repeat center center;
    left: -68px;
    top: 12px;
    content: "";
    display: block;
    position: absolute;
    width: 17px;
    height: 17px;
  }

  .about2 li h4:hover {
    color: #F8F8F8;
    transition: all 1s;
    transform: translateY(-15px);
  }

  .about2 li h4 {
    position: relative;
    display: block;
    text-decoration: none;
    font-family: "Lato";
    color: #ecf0f1;
    text-transform: uppercase;
    padding: 4px 0;
    transition: 0.5s;
  }

  .about2 li h4::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    top: 100%;
    left: 0;
    background: #3498db;
    transition: transform 0.5s;
    transform: scaleX(0);
    transform-origin: right;
  }

  .about2 li h4:hover {
    color: #95a5a6;
  }

  .about2 li h4:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }





  .f30 {
    font-size: 30px;
  }

  .f14 {
    font-size: 14px;
  }

  .about2 li:nth-child(2n):before {
    background: url('/imgs/sy50.png') no-repeat center center;
    right: -69px;
    top: 12px;
    left: auto;
    content: "";
    display: block;
    position: absolute;
    width: 17px;
    height: 17px;
  }

  .about2 li h4 {
    color: #383838;
    transition: all 1s;
  }

  .about2 li p {
    color: #666;
    margin-top: 10px;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .future {
    text-align: center;
    color: #999;
    font-size: 12px;
    margin-top: 17px;
  }

  .clearfix-time:after {
    content: '\20';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .about2 li:nth-child(1) {
    margin-top: 0;
  }

  /* 表示1-3之间的间距 */
  .about2 li {
    float: right;
    width: 45%;
    text-align: left;
    position: relative;
    margin-top: 100px;
  }

  .about2 li:nth-child(2n) {
    float: left;
    text-align: right;
    margin-top: 90px;
  }

  .my-container {
    padding-left: 19%;
    box-sizing: border-box;


    h3 {
      font-size: 18px;
      color: #383838;
      text-align: left;
      margin: 25px 0;

    }

    // 活动推荐
    .banenr {
      width: 100%;
      height: 300px;
      margin: 30px 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .js {
      width: 100%;

      p {
        width: 100%;
        line-height: 50px;
        font-size: 14px;
        // color: $colorD;
        color: #383838;
        margin: 30px 0;

        &:nth-child(2) {
          // text-indent: 30px;
        }
      }
    }

    .dt {
      width: 100%;
      margin: 40px 0;

      img {
        width: 100%;
      }
    }
  }
}
</style>
