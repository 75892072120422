<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽布局 -->
    <div class="my-container">

      <!-- swiper 轮播 -->
      <div class="swiper">
        <!-- <img src="/imgs/banner.jpg" alt="" /> -->
        <div class="swiper-box">
          <swiper :options="swiperOption">
            <!-- 循环想 -->
            <swiper-slide v-for="(item, index) in this.swiperList2" :key="index">
              <a href="javascript:;"><img :src="item.img" /></a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>

      <!-- 活动推荐  st -->
      <div class="banenr" style="display: none;">
        <h2>活动推荐</h2>
        <div class="b-con">
          <a href="javascript:;"><img src="/imgs/ct1.jpg" alt=""></a>
          <a href="javascript:;"><img src="/imgs/ct2.jpg" alt=""></a>
          <a href="javascript:;"><img src="/imgs/ct3.jpg" alt=""></a>
        </div>
      </div>
      <!-- 活动推荐  end -->


      <!-- 风格推荐 st -->
      <div class="mc-fg">
        <h2>发现风格</h2>

        <!-- 循环容器 -->
        <div class="fg-con">
          <!-- 循环项 -->
          <div class="item" @click="fg1()">
            <span>民谣</span>
            <p>ballad</p>
          </div>
          <div class="item" @click="fg2()">
            <span> 古典</span>
            <p>classical</p>
          </div>
          <div class="item" @click="fg3()">
            <span>流行</span>
            <p>popular</p>
          </div>
          <div class="item" @click="fg4()">
            <span>乡村</span>
            <p>rural</p>
          </div>
        </div>
      </div>
      <!-- 风格推荐 end -->


      <!-- 热门推荐 st -->
      <div class="mc-rec">
        <h2>热门推荐</h2>
        <!-- 容器列表 -->
        <div class="rec-con">
          <!-- 循环项 *4 -->
          <div class="rec-item" v-for="(item, index) in this.my_data" :key="index">
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="">
              <!-- 图标 -->
              <i class="iconfont icon-iconset0481"></i>
            </div>
            <!-- 歌曲名称 -->
            <p>{{ item.title }}</p>
            <!-- 歌手名称 -->
            <span>{{ item.name }}</span>
            <span class="i-btn" @click="goDetails(item.id)">在线播放</span>
            <span class="i-btn" @click="downloadMp3(item.src)" v-if="item.sk">音乐下载</span>
            <span class="i-btn" @click="gopay()" v-else>音乐下载</span>
          </div>
        </div>
      </div>
      <!-- 热门推荐 end -->


      <!-- swiper 轮播 -->
      <div class="swiper">
        <!-- <img src="/imgs/banner.jpg" alt="" /> -->
        <div class="swiper-box">
          <swiper :options="swiperOption">
            <!-- 循环想 -->
            <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
              <a href="javascript:;"><img :src="item.img" /></a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>



      <!-- 音乐top榜单 st -->
      <div class="mc-top">
        <h2>音乐榜单 top10</h2>

        <!-- 容器 -->
        <div class="top-con">
          <!-- 循环项 -->
          <div class="top-item" v-for="(item, index) in this.my_data2" :key="index">
            <!-- 歌曲图片 -->
            <div class="item-img">
              <img :src="item.img" alt="">
              <i class="iconfont icon-iconset0481"></i>
            </div>

            <!-- 歌曲名 -->
            <div class="item-title">
              {{ item.title }}
            </div>

            <!-- 歌手 -->
            <div class="item-name">
              {{ item.name }}
            </div>

            <!-- 下载图标 -->
            <!-- <div class="item-pay">
            <i class="iconfont icon-xiazai"></i>
          </div> -->

            <div class="item-name" @click.stop="goDetails(item.id)">
              在线播放
            </div>

            <!-- 指定测试账号的下载功能 -->
            <div class="item-name" @click.stop="downloadMp3(item.src)" v-if="item.sk">
              音乐下载
            </div>

            <div class="item-name" @click.stop="gopay()" v-else>
              音乐下载
            </div>


            <!-- 收费提示 -->
            <div class="item-pay">
              包月15元
            </div>
          </div>
        </div>
      </div>
      <!-- 音乐top榜单 end -->




      <!-- 精选歌手 st -->
      <div class="mc-gs">
        <h2>精选歌手</h2>

        <!-- 循环容器 -->
        <div class="gs-con">
          <!-- 循环项 -->
          <div class="item">
            <!-- 左侧图片 -->
            <img src="/imgs/gs1.jpg" alt="">
            <!-- 右侧信息 -->
            <div class="item-r">
              <!-- 歌手名称 -->
              <div class="r-title">
                张婷婷
              </div>

              <!-- 歌手信息 -->
              <p>
                15 首歌曲
              </p>
            </div>

            <!-- 收藏按钮 -->
            <div class="item-btn">
              <i class="iconfont icon-aixin" v-show="!scBtn" @click="cscBtn()"></i>
              <i class="iconfont icon-aixin1" v-show="scBtn" @click="cscBtn()"></i>
            </div>
          </div>


          <!-- 循环项 -->
          <div class="item">
            <!-- 左侧图片 -->
            <img src="/imgs/gs2.jpg" alt="">
            <!-- 右侧信息 -->
            <div class="item-r">
              <!-- 歌手名称 -->
              <div class="r-title">
                韩笑
              </div>

              <!-- 歌手信息 -->
              <p>
                10 首歌曲
              </p>
            </div>

            <!-- 收藏按钮 -->
            <div class="item-btn">
              <i class="iconfont icon-aixin" v-show="!scBtn2" @click="cscBtn2()"></i>
              <i class="iconfont icon-aixin1" v-show="scBtn2" @click="cscBtn2()"></i>
            </div>
          </div>

          <!-- 循环项 -->
          <div class="item">
            <!-- 左侧图片 -->
            <img src="/imgs/gs3.jpg" alt="">
            <!-- 右侧信息 -->
            <div class="item-r">
              <!-- 歌手名称 -->
              <div class="r-title">
                陈伊轩
              </div>

              <!-- 歌手信息 -->
              <p>
                10 首歌曲
              </p>
            </div>

            <!-- 收藏按钮 -->
            <div class="item-btn">
              <i class="iconfont icon-aixin" v-show="!scBtn3" @click="cscBtn3()"></i>
              <i class="iconfont icon-aixin1" v-show="scBtn3" @click="cscBtn3()"></i>
            </div>
          </div>

          <!-- 循环项 -->
          <div class="item">
            <!-- 左侧图片 -->
            <img src="/imgs/gs4.jpg" alt="">
            <!-- 右侧信息 -->
            <div class="item-r">
              <!-- 歌手名称 -->
              <div class="r-title">
                欢迎入驻~
              </div>

              <!-- 歌手信息 -->
              <p>
                极致唱响期待你的加入~
              </p>
            </div>

          </div>

        </div>
      </div>
      <!-- 精选歌手 end -->



      <!-- 猜你喜欢 st -->
      <div class="mc-xh" style="display: none;">
        <h2>猜你喜欢</h2>

        <!-- 循环容器 -->
        <div class="xh-con">
          <!-- 循环项 -->
          <div class="item">
            <!-- 图片 -->
            <div class="item-img">
              <img src="" alt="">
            </div>
            <!-- 标题 -->
            <h3>
              歌曲名称
            </h3>
            <h4>
              作者
            </h4>
          </div>
        </div>
      </div>
      <!-- 猜你喜欢 end -->





      <!-- banner2 st -->
      <div class="banner2" style="">
        <img src="/imgs/mlist-banner2.jpg" alt="">
      </div>
      <!-- banner2 end -->

    </div>

    <!-- 弹框 -->
    <modal title="请选择支付方式" btnType="1" modalType="middle" :showModal="showModal" @submit="showModal = false"
      @cancel="showModal = false">
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <p>扫码收费：2元</p>
      </template>
    </modal>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
// import navigation from "./../components/navigation";
// import as from "./../components/a";
import Modal from "./../components/Modal.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// import Ma from "./../components/Ma.vue";

export default {
  name: "index",
  data() {
    return {
      tabShow: true,
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "/",
      // 音乐列表
      songList: [],
      // 总页数
      pages: 6,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      my_data2: [],
      drawer: false,
      direction: "ltr",
      my_lyc: [],
      my_title: "",
      imgUrl: "",
      showModal: false,
      scBtn: false,
      scBtn2: false,
      scBtn3: false,
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        loopedSlides: 5,
        effect: "fade",
        slidesPerView: "auto",
        centeredSlides: true,
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/index-banner1.jpg",
        },
        {
          id: "2",
          img: "/imgs/index-banner2.jpg",
        },
      ],
      swiperList2: [
        {
          id: "1",
          img: "/imgs/index-banner3.jpg",
        },
        {
          id: "2",
          img: "/imgs/index-banner4.jpg",
        },
      ],
    };
  },
  components: {
    NavHeader,
    Modal,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    document.title = "极致唱响";
    var user = window.sessionStorage.getItem("user")
    if (user) {
      this.nv_user = JSON.parse(user);
      if (this.nv_user.Username == "mxw") this.init2();
      if (this.nv_user.Username != "mxw") this.init();
    } else {
      this.init();
    }
    console.log(user)
  },
  methods: {
    // 真实支付逻辑
    alipay(data, index) {
      console.log(data);
      var payAmount = 1.0; //支付价格
      var orderName = "音乐下载支付"; //订单名称
      var id = index; //订单名称
      window.open(
        "http://www.cwlo.com.cn/alipay/index.php?payAmount=" +
        payAmount +
        "&orderName=" +
        orderName +
        "&id=" +
        id
      );
    },
    // 获取音乐数据
    init() {
      this.axios.get("/mock/index.json").then((res) => {
        this.my_data = res.data.slice(0, 4);
        this.my_data2 = res.data.slice(4, 14)
      });
    },
    init2() {
      this.axios.get("/mock/index2.json").then((res) => {
        this.my_data = res.data.slice(0, 4);
        this.my_data2 = res.data
      });
    },
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },
    // 收费逻辑
    gopay() {
      // this.$message({
      //   message: '音乐3元一首付费下载',
      //   type: 'warning'
      // });
      // this.$router.push("/pay");
      if (this.state == 0) {
        alert('请充值会员后下载~')
        this.$router.push("/hy");
        // this.showModal = true;
        // console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("请先登录~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },
    // 音乐下载
    downloadMp3(src) {
      const mt = src

      // 设置要下载的mp3文件的路径
      // const fileUrl = `/music/${mt}.mp3`;
      const fileUrl = mt;

      // 创建一个新的a标签元素，用于下载文件
      const link = document.createElement('a');
      link.href = fileUrl;

      // 设置下载属性，以便浏览器可以下载文件
      link.setAttribute('download', '');

      // 将创建的链接添加到页面上
      document.body.appendChild(link);

      // 触发链接的点击事件，以便浏览器可以开始下载文件
      link.click();

      // 在文件下载完成后，从页面中删除链接元素
      document.body.removeChild(link);
    },
    cscBtn() {
      this.scBtn = !this.scBtn
    },
    cscBtn2() {
      this.scBtn2 = !this.scBtn2
    },
    cscBtn3() {
      this.scBtn3 = !this.scBtn3
    },
    fg1() {
      this.$router.push({
        path: "/sound/:" + "5?id=5"
      });
    },
    fg2() {
      this.$router.push({
        path: "/sound/:" + "9?id=9"
      });
    },
    fg3() {
      this.$router.push({
        path: "/sound/:" + "11?id=11"
      });
    },
    fg4() {
      this.$router.push({
        path: "/sound/:" + "12?id=12"
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("https://www.jq22.com/jquery/bootstrap-3.3.4.css");

.index {

  .my-container {
    padding-left: 19%;
    box-sizing: border-box;
    background-color: #f1f4fd;
    padding-top: 35px;
    box-sizing: border-box;

    // 活动推荐
    .banenr {
      width: 100%;

      h2 {
        font-size: 16px;
        font-weight: bold;
        ;
        color: $colorB;
        cursor: pointer;
      }

      .b-con {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 365px;
          height: 200px;
          display: block;
          border-radius: 12px;
          background-color: red;
          cursor: pointer;
          transition: all .3s;

          &:hover {
            transform: translateY(-5px);
          }
        }
      }
    }


    // 风格
    .mc-fg {
      width: 100%;
      margin: 35px 0;

      h2 {
        font-size: 16px;
        font-weight: bold;

        color: $colorB;
        cursor: pointer;
      }

      .fg-con {
        width: 100%;
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item {
          width: 24%;
          height: 150px;
          background: red;
          padding-top: 5%;
          box-sizing: border-box;
          cursor: pointer;
          transition: all .3s;

          &:nth-child(1) {
            background: url('/imgs/背景/1.jpg');
          }

          &:nth-child(2) {
            background: url('/imgs/背景/2.jpg');
          }

          &:nth-child(3) {
            background: url('/imgs/背景/3.jpg');
          }

          &:nth-child(4) {
            background: url('/imgs/背景/4.jpg');
          }

          &:hover {
            transform: translateY(-10px);
          }

          span {
            display: block;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
          }

          p {
            display: block;
            text-align: center;
            width: 100%;
            color: #fff;
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }
    }

    // 热门推荐
    .mc-rec {
      width: 100%;
      margin: 35px 0;

      h2 {
        font-size: 16px;
        font-weight: bold;

        color: $colorB;
        cursor: pointer;
      }

      // 容器列表
      .rec-con {
        width: 100%;
        height: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        // 循环项
        .rec-item {
          width: 270px;
          height: 280px;
          cursor: pointer;

          &:hover {
            .item-img {
              i {
                opacity: 1;
              }
            }
          }

          // 图片
          .item-img {
            width: 100%;
            height: 200px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            i {
              font-size: 40px;
              position: absolute;
              right: 15%;
              bottom: -15%;
              color: $colorA;
              font-weight: bold;
              opacity: 0;
              transition: all .3s;
            }
          }

          // 歌曲名称
          p {
            font-size: 14px;
            color: $colorC;
            margin: 15px 0;
          }

          // 歌手名称
          span {
            font-size: 14px;
            color: $colorD;
          }

          .i-btn {
            display: inline-block;
            // width: 60px;
            padding: 5px;
            box-sizing: border-box;
            // background-color: #f1f4fd;
            color: #0059a5;
            margin: 0 15px;
            border-radius: 3px;
            transition: all .3s;

            &:hover {
              background-color: #fff;

            }
          }
        }
      }

    }

    // 音乐top榜单
    .mc-top {
      width: 100%;
      margin: 35px 0;

      h2 {
        font-size: 16px;
        font-weight: bold;
        ;
        color: $colorB;
        cursor: pointer;
      }

      // 容器
      .top-con {
        width: 100%;
        // height: 600px;
        background: #f1f4fd;
        margin: 30px 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;


        // 循环项
        .top-item {
          width: 44%;
          height: 75px;
          margin: 15px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: all .3s;
          cursor: pointer;
          padding-right: 30px;
          box-sizing: border-box;
          position: relative;

          &:hover {
            background: #fff;

            .item-img {
              i {
                opacity: 1;
              }
            }
          }

          // 歌曲图片
          .item-img {
            width: 75px;
            height: 75px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            i {
              font-size: 35px;
              position: absolute;
              left: 26%;
              top: 17%;
              // color: #888;
              color: #000;
              font-weight: bold;
              opacity: 0;
              transition: all .3s;
            }
          }

          // 歌曲名
          .item-title {
            font-size: 14px;
            color: $colorB;
            width: 120px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }

          // 歌手
          .item-name {
            font-size: 14px;
            color: $colorB;
          }

          // 下载图标
          .item-pay {
            position: absolute;
            width: 70px;
            height: 21px;
            right: -2%;
            top: -10px;
            background-color: red;
            border-radius: 20px;
            color: #fff;
            text-align: center;
            line-height: 21px;
          }
        }
      }
    }

    // 精选歌手
    .mc-gs {
      width: 100%;
      margin: 35px 0;
      margin-bottom: 45px;

      h2 {
        font-size: 16px;
        font-weight: bold;
        ;
        color: $colorB;
        cursor: pointer;
      }

      // 容器
      .gs-con {
        width: 100%;
        margin: 25px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item {
          width: 300px;
          height: 150px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
          position: relative;

          img {
            width: 110px;
            border-radius: 15px;
          }

          // 右侧
          .item-r {
            margin-left: 15px;

            .r-title {
              font-size: 16px;
              font-weight: bold;
              color: $colorB;
            }

            p {
              font-size: 12px;
              margin-top: 10px;
              color: $colorB;

            }
          }

          // 收藏按钮
          .item-btn {
            position: absolute;
            top: 5%;
            right: 5%;

            i {
              font-size: 21px;
              cursor: pointer;

              &:nth-child(2) {
                color: $colorB;
              }
            }

          }
        }
      }


    }

    // 精选歌手
    .mc-xh {
      width: 100%;
      margin: 35px 0;


      h2 {
        font-size: 16px;
        font-weight: bold;

        color: $colorB;
        cursor: pointer;
      }

    }


    // banenr2 
    .banner2 {
      width: 100%;
      // height: 400px;
      margin: 30px 0;
      background: pink;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
