<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽布局 -->
    <div class="my-container">
      <!-- 活动推荐  st -->
      <div class="banenr">
        <img src="/imgs/ac-banner.jpg" alt="" />
      </div>
      <!-- 活动推荐  end -->

      <!-- 蛋白兔 st -->
      <div class="mc-rec">
        <h2>蛋白兔</h2>
        <!-- 容器列表 -->
        <div class="rec-con">
          <!-- 循环项 *4 -->
          <div
            class="rec-item"
            v-for="(item, index) in this.m1"
            :key="index"
            @click="gopay"
          >
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
              <!-- 图标 -->
              <i class="iconfont icon-xiazai"></i>
            </div>
            <!-- 歌曲名称 -->
            <p>下载付费图片：2元</p>
            <!-- 歌手名称 -->
            <!-- <span>齐静春</span> -->
          </div>
        </div>
      </div>
      <!-- 蛋白兔 end -->

      <!-- 搞怪邱雯表情包 st -->
      <div class="mc-rec">
        <h2>搞怪邱雯表情包</h2>
        <!-- 容器列表 -->
        <div class="rec-con">
          <!-- 循环项 *4 -->
          <div
            class="rec-item"
            v-for="(item, index) in this.m2"
            :key="index"
            @click="gopay"
          >
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
              <!-- 图标 -->
              <i class="iconfont icon-xiazai"></i>
            </div>
            <!-- 歌曲名称 -->
            <p>下载付费图片：2元</p>
            <!-- 歌手名称 -->
            <!-- <span>齐静春</span> -->
          </div>
        </div>
      </div>
      <!-- 搞怪邱雯表情包 end -->

      <!-- 咕叽叽戏精 st -->
      <div class="mc-rec">
        <h2>咕叽叽戏精</h2>
        <!-- 容器列表 -->
        <div class="rec-con">
          <!-- 循环项 *4 -->
          <div
            class="rec-item"
            v-for="(item, index) in this.m3"
            :key="index"
            @click="gopay"
          >
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
              <!-- 图标 -->
              <i class="iconfont icon-xiazai"></i>
            </div>
            <!-- 歌曲名称 -->
            <p>下载付费图片：2元</p>
            <!-- 歌手名称 -->
            <!-- <span>齐静春</span> -->
          </div>
        </div>
      </div>
      <!-- 咕叽叽戏精 end -->

      <!-- 可爱的黑喵 st -->
      <div class="mc-rec">
        <h2>可爱的黑喵</h2>
        <!-- 容器列表 -->
        <div class="rec-con">
          <!-- 循环项 *4 -->
          <div
            class="rec-item"
            v-for="(item, index) in this.m4"
            :key="index"
            @click="gopay"
          >
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
              <!-- 图标 -->
              <i class="iconfont icon-xiazai"></i>
            </div>
            <!-- 歌曲名称 -->
            <p>下载付费图片：2元</p>
            <!-- 歌手名称 -->
            <!-- <span>齐静春</span> -->
          </div>
        </div>
      </div>
      <!-- 可爱的黑喵 end -->

      <!-- 可爱的小吉宝宝 st -->
      <div class="mc-rec">
        <h2>可爱的小吉宝宝</h2>
        <!-- 容器列表 -->
        <div class="rec-con">
          <!-- 循环项 *4 -->
          <div
            class="rec-item"
            v-for="(item, index) in this.m5"
            :key="index"
            @click="gopay"
          >
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
              <!-- 图标 -->
              <i class="iconfont icon-xiazai"></i>
            </div>
            <!-- 歌曲名称 -->
            <p>下载付费图片：2元</p>
            <!-- 歌手名称 -->
            <!-- <span>齐静春</span> -->
          </div>
        </div>
      </div>
      <!-- 可爱的小吉宝宝 end -->

      <!-- 可爱萌趣的兔熊 st -->
      <div class="mc-rec">
        <h2>可爱萌趣的兔熊</h2>
        <!-- 容器列表 -->
        <div class="rec-con">
          <!-- 循环项 *4 -->
          <div
            class="rec-item"
            v-for="(item, index) in this.m6"
            :key="index"
            @click="gopay"
          >
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
              <!-- 图标 -->
              <i class="iconfont icon-xiazai"></i>
            </div>
            <!-- 歌曲名称 -->
            <p>下载付费图片：2元</p>
            <!-- 歌手名称 -->
            <!-- <span>齐静春</span> -->
          </div>
        </div>
      </div>
      <!-- 可爱萌趣的兔熊 end -->

      <!-- 面瘫凌表情包  st -->
      <div class="mc-rec">
        <h2>面瘫凌表情包</h2>
        <!-- 容器列表 -->
        <div class="rec-con">
          <!-- 循环项 *4 -->
          <div
            class="rec-item"
            v-for="(item, index) in this.m7"
            :key="index"
            @click="gopay"
          >
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
              <!-- 图标 -->
              <i class="iconfont icon-xiazai"></i>
            </div>
            <!-- 歌曲名称 -->
            <p>下载付费图片：2元</p>
            <!-- 歌手名称 -->
            <!-- <span>齐静春</span> -->
          </div>
        </div>
      </div>
      <!-- 面瘫凌表情包  end -->

      <!-- 蚊帐公主表情包  st -->
      <div class="mc-rec">
        <h2>蚊帐公主表情包</h2>
        <!-- 容器列表 -->
        <div class="rec-con">
          <!-- 循环项 *4 -->
          <div
            class="rec-item"
            v-for="(item, index) in this.m8"
            :key="index"
            @click="gopay"
          >
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
              <!-- 图标 -->
              <i class="iconfont icon-xiazai"></i>
            </div>
            <!-- 歌曲名称 -->
            <p>下载付费图片：2元</p>
            <!-- 歌手名称 -->
            <!-- <span>齐静春</span> -->
          </div>
        </div>
      </div>
      <!-- 蚊帐公主表情包  end -->
    </div>

    <!-- 弹框 -->
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <p>扫码收费：2元</p>
      </template>
    </modal>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
// import navigation from "./../components/navigation";
// import as from "./../components/a";
import Modal from "./../components/Modal.vue";
// import Ma from "./../components/Ma.vue";

export default {
  name: "index",
  data() {
    return {
      tabShow: true,
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "/",
      // 音乐列表
      songList: [],
      // 总页数
      pages: 6,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      drawer: false,
      direction: "ltr",
      my_lyc: [],
      my_title: "",
      imgUrl: "",
      showModal: false,
      m1: [],
      m2: [],
      m3: [],
      m4: [],
      m5: [],
      m6: [],
      m7: [],
      m8: [],
    };
  },
  components: {
    NavHeader,
    Modal,
  },
  mounted() {
    document.title = "元跃科技";
    this.init();
  },
  methods: {
    // 真实支付逻辑
    alipay(data, index) {
      console.log(data);
      var payAmount = 1.0; //支付价格
      var orderName = "音乐下载支付"; //订单名称
      var id = index; //订单名称
      window.open(
        "http://www.cwlo.com.cn/alipay/index.php?payAmount=" +
          payAmount +
          "&orderName=" +
          orderName +
          "&id=" +
          id
      );
    },
    init() {
      // 渲染数据
      this.axios.get("/mock/a.json").then((res) => {
        var data = res.data;
        this.m1 = data.slice(0, 9);
        this.m2 = data.slice(9, 39);
        this.m3 = data.slice(39, 49);
        this.m4 = data.slice(49, 58);
        this.m5 = data.slice(58, 67);
        this.m6 = data.slice(67, 76);
        this.m7 = data.slice(76, 106);
        this.m8 = data.slice(106, 136);
      });
    },
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },
    // 收费逻辑
    gopay() {
      if (this.state == 0) {
        alert("请充值会员后下载~");
        this.$router.push("/hy");
        // this.showModal = true;
        // console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("请先登录~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("https://www.jq22.com/jquery/bootstrap-3.3.4.css");
.index {
  .my-container {
    padding-left: 19%;
    box-sizing: border-box;
    // 活动推荐
    .banenr {
      width: 100%;
      height: 300px;
      margin: 30px 0;
      img {
        width: 100%;
        height: 100%;
      }
    }

    // 热门推荐
    .mc-rec {
      width: 100%;
      h2 {
        font-size: 16px;
        font-weight: bold;
        color: $colorB;
        cursor: pointer;
      }

      // 容器列表
      .rec-con {
        width: 100%;
        display: flex;
        margin: 30px 0;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 40px 0;
        // 循环项
        .rec-item {
          width: 270px;
          cursor: pointer;
          margin: 10px 0;
          &:hover {
            .item-img {
              i {
                opacity: 1;
              }
            }
          }
          // 图片
          .item-img {
            width: 100%;
            height: 270px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            i {
              font-size: 40px;
              position: absolute;
              right: 15%;
              bottom: -15%;
              color: $colorA;
              // font-weight: bold;
              opacity: 0;
              transition: all 0.3s;
            }
          }

          // 歌曲名称
          p {
            font-size: 14px;
            color: $colorC;
            margin: 15px 0;
          }

          // 歌手名称
          span {
            font-size: 14px;
            color: $colorD;
          }
        }
      }
    }
  }
}
</style>
