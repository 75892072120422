<template>
  <div class="login-index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>
    <div id="wrapper" class="login-page">
      <div id="login_form" class="form">
        <form class="register-form">
          <input type="text" placeholder="请输入用户名" id="r_user_name" v-model="rUsername" />
          <input type="password" placeholder="请输入密码" id="r_password" v-model="rPassword" />
          <input type="text" placeholder="请输入手机号码" v-model="rPhone" />
          <input type="text" placeholder="请输入姓名" id="r_password" />
          <input type="text" placeholder="请输入身份证号码" id="r_password" />

          <button id="create" @click="register()" type="button">注册账户</button>
          <p class="message" @click="fnc1()">
            > 已经有了一个账户? <a href="#">立刻登录</a>
          </p>
          <p class="message" @click="fnc1()">
            <a href="/#/jf">用户协议</a>
          </p>
          <p style="margin-top: 5px; font-size: 12px; color: #888">
            未成年人登录后只有周末时间能上线4个小时
          </p>
        </form>
        <form class="login-form">
          <input type="text" placeholder="请输入用户名/手机号" id="user_name" v-model="lUsername" />
          <input type="password" placeholder="请输入密码" id="password" v-model="lPassword" />
          <button id="login" @click="logo()" type="button">登录</button>
          <p class="message" @click="fnc1()">还没有账户? <a href="#">立刻注册</a></p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import NavHeader from "./../components/NavHeader";
export default {
  data() {
    return {
      href: "index.html",
      // 用户名
      lUsername: "",
      // 密码
      lPassword: "",
      // 手机号
      rPhone: "",
      // 获取收费跳转参数
      k: this.$route.query.k,
      // 获取到 user 用户信息
      logo_user: JSON.parse(window.localStorage.getItem("user")),
      // 获取到 sor 的用户信息
      logo_user2: JSON.parse(window.sessionStorage.getItem("user")),
      rUsername: "",
      rPassword: "",

    };
  },

  methods: {
    fnc1() {
      $("form").animate(
        {
          height: "toggle",
          opacity: "toggle",
        },
        "slow"
      );
    },
    // 登录逻辑
    logo() {
      // 声明一个用户变量
      let loginUser = null;

      // 获取已注册的用户列表
      const users = JSON.parse(window.localStorage.getItem("users")) || [];

      // 检查用户名或手机号是否存在
      loginUser = users.find(user => user.Username === this.lUsername || user.rPhone === this.lUsername);

      // 如果找不到用户，提示并终止
      if (!loginUser) {
        alert("用户不存在");
        return;
      }

      // 验证密码
      if (this.lPassword !== loginUser.Password) {
        alert("密码错误");
        return;
      }

      // 登录成功，存储用户信息
      window.localStorage.setItem("user", JSON.stringify(loginUser));

      // 登录状态设置为0（已登录）
      window.localStorage.setItem("state", "0");

      // 跳转到首页并刷新页面
      this.$router.push("/");
      window.location.reload();
    },
    // 注册逻辑
    register() {
      // 获取用户输入的用户名、密码和手机号
      const ruser = {
        Username: this.rUsername,
        Password: this.rPassword,
        rPhone: this.rPhone // 新增手机号字段
      };

      // 判断用户名、密码和手机号是否为空
      if (!this.rUsername) {
        alert("请输入用户名");
        return; // 停留在原地
      }
      if (!this.rPassword) {
        alert("请输入密码");
        return; // 停留在原地
      }
      if (!this.rPhone) {
        alert("请输入手机号");
        return; // 停留在原地
      }

      // 验证手机号格式
      const phoneRegex = /^1[3-9]\d{9}$/; // 正则表达式：符合中国大陆手机号格式（以1开头，后面跟10位数字）
      if (!phoneRegex.test(this.rPhone)) {
        alert("请输入有效的手机号");
        return; // 停留在原地
      }

      // 获取已注册的用户列表
      let users = JSON.parse(window.localStorage.getItem("users")) || [];

      // 检查用户名或手机号是否已被注册
      const existingUser = users.find(user => user.Username === ruser.Username || user.rPhone === ruser.rPhone);
      if (existingUser) {
        alert("用户名或手机号已被注册");
        return; // 停留在原地
      }

      // 如果检查通过，保存新用户信息
      users.push(ruser);
      window.localStorage.setItem("users", JSON.stringify(users)); // 更新用户列表

      // 登录状态设置为0（已登录）
      window.localStorage.setItem("state", "0");

      // 跳转到首页并刷新页面
      this.$router.push("/");
      window.location.reload();
    },
    // 跳转协议
    Xy() {
      this.$router.push("/xy");
    },
  },

  components: {
    NavHeader,
  },
  mounted() {
    document.title = "极致唱响";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.login-index {
  background: url('/imgs/bj.jpg');
  padding-bottom: 100px;
  background-size: cover;
}

.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  // height: 525px;
  box-sizing: border-box;
  margin-left: 42%;

}

.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 360px;
  // margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form button {
  font-family: "Microsoft YaHei", "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: $colorB;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:hover,
.form button:active,
.form button:focus {
  background: $colorB;
}

.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}

.form .message a {
  color: $colorB;
  text-decoration: none;
}

.form .register-form {
  display: none;
}

.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}

.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}

.container .info {
  margin: 50px auto;
  text-align: center;
}

.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}

.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}

.container .info span a {
  color: #000000;
  text-decoration: none;
}

.container .info span .fa {
  color: #ef3b3a;
}
</style>
