<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽布局 -->
    <div class="my-container">


      <!-- swiper 轮播 -->
      <div class="swiper">
        <!-- <img src="/imgs/banner.jpg" alt="" /> -->
        <div class="swiper-box">
          <swiper :options="swiperOption">
            <!-- 循环想 -->
            <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
              <a href="javascript:;"><img :src="item.img" /></a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>

      <!-- 潮流音乐 st -->
      <div class="mc-top">
        <h2>潮流音乐</h2>

        <!-- 容器 -->
        <div class="top-con">
          <!-- 循环项 -->
          <div class="top-item" v-for="(item, index) in this.my_data" :key="index">
            <!-- 歌曲图片 -->
            <div class="item-img">
              <img :src="item.img" alt="">
              <i class="iconfont icon-bofang"></i>
            </div>

            <!-- 歌曲名 -->
            <div class="item-title">
              {{ item.title }}
            </div>

            <!-- 歌手 -->
            <div class="item-name">
              {{ item.name }}
            </div>



            <!-- 下载图标 -->
            <!-- <div class="item-pay">
            <i class="iconfont icon-xiazai"></i>
          </div> -->

            <div class="item-name" @click.stop="goDetails(item.id)">
              在线播放
            </div>

            <!-- 指定测试账号的下载功能 -->
            <div class="item-name" @click.stop="downloadMp3(item.src)" v-if="item.sk">
              音乐下载
            </div>

            <div class="item-name" @click.stop="gopay()" v-else>
              音乐下载
            </div>

            <!-- 收费提示 -->
            <div class="item-pay">
              包月15元
            </div>
          </div>
        </div>
      </div>
      <!-- 潮流音乐 end -->

      <!-- 经典民谣 st -->
      <div class="mc-top">
        <h2>经典民谣</h2>

        <!-- 容器 -->
        <div class="top-con">
          <!-- 循环项 -->
          <div class="top-item" v-for="(item, index) in this.my_data2" :key="index">
            <!-- 歌曲图片 -->
            <div class="item-img">
              <img :src="item.img" alt="">
              <i class="iconfont icon-bofang"></i>
            </div>

            <!-- 歌曲名 -->
            <div class="item-title">
              {{ item.title }}
            </div>

            <!-- 歌手 -->
            <div class="item-name">
              {{ item.name }}
            </div>

            <!-- 下载图标 -->
            <!-- <div class="item-pay">
            <i class="iconfont icon-xiazai"></i>
          </div> -->

            <div class="item-name" @click.stop="goDetails(item.id)">
              在线播放
            </div>

            <!-- 指定测试账号的下载功能 -->
            <div class="item-name" @click.stop="gopay()" v-if="item.sk">
              音乐下载
            </div>

            <div class="item-name" @click.stop="gopay()" v-else>
              音乐下载
            </div>

            <!-- 收费提示 -->
            <div class="item-pay">
              包月15元
            </div>

          </div>
        </div>
      </div>
      <!-- 经典民谣 end -->


      <!-- 流行乡村 st -->
      <div class="mc-top">
        <h2>流行乡村</h2>

        <!-- 容器 -->
        <div class="top-con">
          <!-- 循环项 -->
          <div class="top-item" v-for="(item, index) in this.my_data3" :key="index">
            <!-- 歌曲图片 -->
            <div class="item-img">
              <img :src="item.img" alt="">
              <i class="iconfont icon-bofang"></i>
            </div>

            <!-- 歌曲名 -->
            <div class="item-title">
              {{ item.title }}
            </div>

            <!-- 歌手 -->
            <div class="item-name">
              {{ item.name }}
            </div>


            <div class="item-name" @click.stop="goDetails(item.id)">
              在线播放
            </div>

            <!-- 指定测试账号的下载功能 -->
            <div class="item-name" @click.stop="gopay()" v-if="item.sk">
              音乐下载
            </div>

            <div class="item-name" @click.stop="gopay()" v-else>
              音乐下载
            </div>

            <!-- 收费提示 -->
            <div class="item-pay">
              包月15元
            </div>

          </div>
        </div>
      </div>
      <!-- 流行乡村 end -->

      <div>

      </div>
    </div>

    <!-- 弹框 -->
    <modal title="请选择支付方式" btnType="1" modalType="middle" :showModal="showModal" @submit="showModal = false"
      @cancel="showModal = false">
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <p>扫码收费：2元</p>
      </template>
    </modal>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
import Modal from "./../components/Modal.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "index",
  data() {
    return {
      tabShow: true,
      state: JSON.parse(window.localStorage.getItem("state")),
      nv_user: "",
      nv_user2: JSON.parse(window.sessionStorage.getItem("user")),
      href: "/",
      // 音乐列表
      songList: [],
      songList2: [],
      songList3: [],
      // 总页数
      pages: 6,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      my_data2: [],
      my_data3: [],
      drawer: false,
      direction: "ltr",
      my_lyc: [],
      my_title: "",
      imgUrl: "",
      showModal: false,
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        loopedSlides: 5,
        effect: "fade",
        slidesPerView: "auto",
        centeredSlides: true,
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/banner.jpg",
        },
        {
          id: "2",
          img: "/imgs/banner2.jpg",
        },
      ],
    };
  },
  components: {
    NavHeader,
    Modal,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    document.title = "极致唱响";
    var user = window.sessionStorage.getItem("user")
    if (user) {
      this.nv_user = JSON.parse(user);
      if (this.nv_user.Username == "mxw") this.init2();
      if (this.nv_user.Username != "mxw") this.init();
    } else {
      this.init();
    }
    console.log(user)

  },
  methods: {
    // 真实支付逻辑
    alipay(data, index) {
      console.log(data);
      var payAmount = 1.0; //支付价格
      var orderName = "音乐下载支付"; //订单名称
      var id = index; //订单名称
      window.open(
        "http://www.cwlo.com.cn/alipay/index.php?payAmount=" +
        payAmount +
        "&orderName=" +
        orderName +
        "&id=" +
        id
      );
    },
    // 获取音乐数据
    init() {
      this.axios.get("/mock/index.json").then((res) => {
        this.my_data = res.data.slice(0, 10);
        this.my_data2 = res.data.slice(10, 20);
        this.my_data3 = res.data.slice(20, 30);
      });
    },
    init2() {
      this.axios.get("/mock/index2.json").then((res) => {
        this.my_data = res.data.slice(0, 10);
        this.my_data2 = res.data.slice(10, 21);
      });
    },
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },
    // 收费逻辑
    gopay() {

      // this.$message({
      //   message: '音乐3元一首付费下载',
      //   type: 'warning'
      // });
      // this.$router.push("/pay");

      if (this.state == 0) {
        alert('请充值会员后下载~')
        this.$router.push("/hy");
        // this.showModal = true;
        // console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("请先登录~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },

    // 下载音乐逻辑
    downloadMp3(src) {
      const mt = src

      // 设置要下载的mp3文件的路径
      // const fileUrl = `/music/${mt}.mp3`;
      const fileUrl = mt;

      // 创建一个新的a标签元素，用于下载文件
      const link = document.createElement('a');
      link.href = fileUrl;

      // 设置下载属性，以便浏览器可以下载文件
      link.setAttribute('download', '');

      // 将创建的链接添加到页面上
      document.body.appendChild(link);

      // 触发链接的点击事件，以便浏览器可以开始下载文件
      link.click();

      // 在文件下载完成后，从页面中删除链接元素
      document.body.removeChild(link);
    }

  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("https://www.jq22.com/jquery/bootstrap-3.3.4.css");

.index {

  .my-container {
    padding-left: 19%;
    box-sizing: border-box;

    // 轮播
    .swiper {
      margin-bottom: 35px;
    }

    // 活动推荐
    .banenr {
      width: 100%;
      height: 300px;
      margin: 30px 0;

      img {
        width: 100%;
        height: 100%;
      }

    }

    // 音乐top榜单
    .mc-top {
      width: 100%;

      h2 {
        font-size: 16px;
        font-weight: bold;
        ;
        color: $colorB;
        cursor: pointer;
      }

      // 容器
      .top-con {
        width: 100%;
        // height: 600px;
        background: #f1f4fd;
        margin: 30px 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;

        // 循环项
        .top-item {
          width: 44%;
          height: 75px;
          margin: 15px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: all .3s;
          cursor: pointer;
          padding-right: 30px;
          box-sizing: border-box;
          position: relative;

          &:hover {
            background: #fff;

            .item-img {
              i {
                opacity: 1;
              }
            }
          }

          // 歌曲图片
          .item-img {
            width: 75px;
            height: 75px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            i {
              font-size: 35px;
              position: absolute;
              left: 26%;
              top: 17%;
              // color: #888;
              color: #000;
              font-weight: bold;
              opacity: 0;
              transition: all .3s;
            }
          }

          // 歌曲名
          .item-title {
            font-size: 14px;
            color: $colorB;
            width: 120px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }

          // 歌手
          .item-name {
            font-size: 14px;
            color: $colorB;
          }

          // 下载图标
          .item-pay {
            position: absolute;
            width: 70px;
            height: 21px;
            right: -2%;
            top: -10px;
            background-color: red;
            border-radius: 20px;
            color: #fff;
            text-align: center;
            line-height: 21px;
          }
        }
      }
    }


  }
}
</style>
